import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const PrivacyCookies = () => {
  const lang = "pl"

  return (
    <Layout lang={lang} translation="/cookies-policy/">
      <SEO title="Polityka cookies" />

      <section className="default-content">
        <h1>Polityka Plików Cookies</h1>
        <p>
          Niniejsza Polityka dotycząca Ciasteczek odnosi się do strony
          unitycentre.pl Przez używanie serwisu unitycentre.pl wyrażasz zgodę na
          używanie ciasteczek zgodnie z tą Polityką Ciasteczek. Jeżeli nie
          zgadzasz się na używanie przez nas ciasteczek, powinieneś zmienić
          ustawienia swojej przeglądarki w odpowiedni sposób lub zrezygnować z
          używania strony unitycentre.pl.
        </p>
        <h4>Co to są ciasteczka?</h4>
        <p>
          Ciasteczka (ang. cookies) to niewielkie pliki, zapisywane i
          przechowywane na twoim komputerze, tablecie lub smartphonie podczas
          gdy odwiedzasz różne strony w internecie. Ciasteczko zazwyczaj zawiera
          nazwę strony internetowej, z której pochodzi, „długość życia”
          ciasteczka (to znaczy czas jego istnienia), oraz przypadkowo
          wygenerowany unikalny numer służący do identyfikacji przeglądarki, z
          jakiej następuje połączenie ze stroną internetową.
        </p>
        <h4>Do czego używamy ciasteczek?</h4>
        <p>
          unitycentre.pl używa ciasteczek w różnych celach: by serwis działał
          szybciej i był łatwiejszy w użyciu, do zbierania anonimowych,
          zagregowanych statystyk, które pozwalają nam zrozumieć jak ludzie
          używają naszych stron i pomagają w poprawianiu ich funkcjonalności i
          zawartości. Używając ciasteczek w wyżej opisany sposób nigdy nie
          identyfikujemy tożsamości użytkowników na podstawie informacji
          przechowywanych w ciasteczkach.
        </p>
        <h4>Jak długo przechowywane są dane w ciasteczkach?</h4>
        <p>
          Na stronie unitycentre.pl mogą być używane dwa rodzaje ciasteczek –
          sesyjne oraz stałe. Te pierwsze pozostają na Twoim urządzeniu jedynie
          podczas korzystania ze strony unitycentre.pl. Ciasteczka stałe
          pozostają na Twoim urządzeniu tak długo jak długo mają ustawiony czas
          życia lub do momentu kiedy je usuniesz.
        </p>
        <h4>Rodzaje ciasteczek używanych na stronie unitycentre.pl</h4>
        <p>
          Konieczne do działania stron – Niezbędne do prawidłowego
          funkcjonowania strony unitycentre.pl, pozwalają Ci na poruszanie się
          po nich oraz używanie ich elementów. Przykładowo mogą zapamiętywać
          poprzednie czynności (np. otwarte teksty) podczas wracania na stronę w
          tej samej sesji. Poprawiające wydajność – Zbieranie informacji o tym
          jak odwiedzający korzystają ze strony unitycentre.pl poprzez
          dostarczanie informacji na temat obszarów które odwiedzają, czasu jaki
          na nich spędzają oraz problemów jakie na nich napotykają, jak np.
          komunikaty o błędach. To pozwala nam poprawiać działanie strony
          unitycentre.pl.
        </p>
        <h4>Czy używamy ciasteczek podmiotów trzecich?</h4>
        <p>
          Tak, korzystając z serwisu unitycentre.pl możesz otrzymywać ciasteczka
          pochodzące od współpracujących z unitycentre.pl podmiotów trzecich
          takich jak np. Facebook czy Google. Więcej informacji na temat tych
          ciasteczek możesz znaleźć na stronach internetowych poszczególnych
          podmiotów trzecich.
        </p>
        <h4>
          W jaki sposób mogę zmienić ustawienia dot. ciasteczek albo je usunąć?
        </h4>
        <p>
          Większość przeglądarek internetowych jest początkowo ustawionych na
          automatyczne przyjmowanie ciasteczek. Możesz jednak zmienić ustawienia
          przeglądarki tak, aby ciasteczka były blokowane – w całości lub w
          jakiejś części, np. tylko od stron trzecich, albo aby każdorazowo
          otrzymywać komunikat w momencie kiedy ciasteczka są wysyłane na Twoje
          urządzenie. Pamiętaj jednak, że jeżeli zablokujesz używane przez nas
          ciasteczka, może to negatywnie wpłynąć na wygodę korzystania ze strony
          unitycentre.pl, na przykład możesz nie być w stanie odwiedzić pewnych
          obszarów strony unitycentre.pl bądź nie otrzymywać spersonalizowanych
          informacji podczas ich przeglądania. Uniemożliwisz nam także zbieranie
          anonimowych informacji nt. używania naszych stron w celu stałego
          poprawiania zawartości strony unitycentre.pl.
        </p>
      </section>
    </Layout>
  )
}

export default PrivacyCookies
